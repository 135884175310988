export type BrowserTestConfig = {
  env(key: string): string | undefined;
};

export class EnvHelper {
  static envVars: Object = {
    KYROS_CALLBACK_URL: process.env.KYROS_CALLBACK_URL,
    VBTC_API: process.env.VBTC_API,
    KYROS_API: process.env.KYROS_API,
    PAYMENT_GATEWAY_API: process.env.PAYMENT_GATEWAY_API,
    PRICE_PROXY_URL: process.env.PRICE_PROXY_URL,
    ENV: process.env.ENV,
    FEATURE_TOGGLE_FINLAND_ENABLED: process.env.FEATURE_TOGGLE_FINLAND_ENABLED,
    FEATURE_TOGGLE_SWEDEN_BANKID: process.env.FEATURE_TOGGLE_SWEDEN_BANKID,
    FEATURE_TOGGLE_ALLOW_EXTERNAL_WALLETS_FOR_TRANSFER: process.env.FEATURE_TOGGLE_ALLOW_EXTERNAL_WALLETS_FOR_TRANSFER,
    FEATURE_TOGGLE_PAYMENTS: process.env.FEATURE_TOGGLE_PAYMENTS,
    FEATURE_TOGGLE_LIGHT_MODE: process.env.FEATURE_TOGGLE_LIGHT_MODE,
    VERIFICATION_SERVICE_URL: process.env.VERIFICATION_SERVICE_URL,
    BRAND: process.env.BRAND,
    ID_AUTH_API: process.env.ID_AUTH_API,
    FEATURE_TOGGLE_TRANSFER_FEE: process.env.FEATURE_TOGGLE_TRANSFER_FEE,
    PAYMENT_PAYSAFE_TOKEN: process.env.PAYMENT_PAYSAFE_TOKEN,
    PAYMENT_PAYSAFE_ACCOUNT_ID_DDK: process.env.PAYMENT_PAYSAFE_ACCOUNT_ID_DKK,
    PAYMENT_PAYSAFE_ACCOUNT_ID_EUR: process.env.PAYMENT_PAYSAFE_ACCOUNT_ID_EUR,
    PAYMENT_PAYSAFE_ACCOUNT_ID_SEK: process.env.PAYMENT_PAYSAFE_ACCOUNT_ID_SEK,
    PAYMENT_PAYSAFE_ACCOUNT_ID_NOK: process.env.PAYMENT_PAYSAFE_ACCOUNT_ID_NOK,
    PAYMENT_PROXY_MERCHANT_ID: process.env.PAYMENT_PROXY_MERCHANT_ID,
    PAYMENT_SDK_KEY: process.env.PAYMENT_SDK_KEY,
    PAYMENT_SDK_ENVIRONMENT: process.env.PAYMENT_SDK_ENVIRONMENT,
    PAYMENT_SDK_APPLE_PAY_ENABLED: process.env.PAYMENT_SDK_APPLE_PAY_ENABLED,
    PAYMENT_SDK_APPLE_PAY_COUNTRY: process.env.PAYMENT_SDK_APPLE_PAY_COUNTRY,
    PAYMENT_SDK_APPLE_PAY_MERCHANT_ID: process.env.PAYMENT_SDK_APPLE_PAY_MERCHANT_ID,
    PAYMENT_SDK_GPAY_ENABLED: process.env.PAYMENT_SDK_GPAY_ENABLED,
    PAYMENT_SDK_GPAY_GATEWAY_MERCHANT_ID: process.env.PAYMENT_SDK_GPAY_GATEWAY_MERCHANT_ID,
    PAYMENT_SDK_GPAY_MERCHANT_ID: process.env.PAYMENT_SDK_GPAY_MERCHANT_ID,
    PAYMENT_SDK_GPAY_MERCHANT_NAME: process.env.PAYMENT_SDK_GPAY_MERCHANT_NAME,
    PAYMENT_SDK_GPAY_ENVIRONMENT: process.env.PAYMENT_SDK_GPAY_ENVIRONMENT,
    PAYMENT_ENABLED_CARDS_BRANDS: process.env.PAYMENT_ENABLED_CARDS_BRANDS,
    PAYMENT_SDK_API_HOST: process.env.PAYMENT_SDK_API_HOST,
    PAYMENT_SDK_PAYSAFE: process.env.PAYMENT_SDK_PAYSAFE,
    PAYMENT_SDK_PCI_PROXY_URL: process.env.PAYMENT_SDK_PCI_PROXY_URL,
    HOSTED_FIELDS_HOSTNAME: process.env.HOSTED_FIELDS_HOSTNAME,
    DATADOG_CLIENT_TOKEN: process.env.DATADOG_CLIENT_TOKEN,
    DATADOG_ENV: process.env.DATADOG_ENV,
    DATADOG_ENABLED: process.env.DATADOG_ENABLED,
  };

  static get(envVarName: string): string | undefined {
    const config = EnvHelper.getConfig();
    if (!config) {
      return;
    }
    return config.env(envVarName);
  }

  static isBrowserTestEnv(): boolean {
    return process.env.ENV === 'test' && (window as any).Cypress;
  }

  static getConfig(): BrowserTestConfig | null {
    if (!EnvHelper.isBrowserTestEnv()) {
      return null;
    }
    return (window as any).Cypress;
  }

  static isPaymentFlowFeatureEnabled(): boolean {
    const paymentFlowFeature = EnvHelper.get('FEATURE_TOGGLE_PAYMENTS') || process.env.FEATURE_TOGGLE_PAYMENTS;
    return paymentFlowFeature === 'true';
  }
}

import { Brand } from '../models/Brand';

export type BrandMapping<T> = { [key in Brand]: T };
export type BrandMappingOptional<T> = { [key in Brand]: T | null };

export const isBrandDefined = (): boolean => {
  return (
    !!process.env.BRAND &&
    process.env.BRAND.length > 0 &&
    !Object.keys(Brand).some((expectedBrand) => expectedBrand === process.env.BRAND)
  );
};

export const getFromMapping = <T>(mapping: BrandMapping<T>): T => {
  if (!isBrandDefined()) {
    throwBrandError();
  }
  return mapping[process.env.BRAND as Brand];
};

const colors: BrandMapping<string> = {
  [Brand.Frontnode]: '#3c44ff',
  [Brand.Blockforia]: '#8338EC',
  [Brand.Omnidrive]: '#fd7d00',
};

const throwBrandError = () => {
  throw new Error('Env variable Brand is not defined or contains unexpected value.');
};

export class BrandHelper {
  static checkBrand() {
    if (!process.env.BRAND) {
      throw new Error('Env variable Brand is not defined');
    }
    if (!Object.values(Brand).some((expectedBrand: string) => expectedBrand === process.env.BRAND)) {
      throw new Error(`Unexpected brand: ${process.env.BRAND}`);
    }
  }

  static brandName(): string {
    if (!isBrandDefined()) {
      throwBrandError();
    }
    const [firstLetter, ...otherLetters] = process.env.BRAND as String;
    return [firstLetter.toUpperCase(), ...otherLetters].join('');
  }

  static getBrandColor(): string {
    return getFromMapping(colors);
  }

  static getBrand(): string {
    BrandHelper.checkBrand();
    return process.env.BRAND as string;
  }

  static brandFontFamily(): string {
    if (process.env.BRAND === Brand.Frontnode) {
      return 'Test Untitled Sans' as string;
    }
    if (process.env.BRAND === Brand.Omnidrive) {
      return 'Sora' as string;
    }
    if (process.env.BRAND === Brand.Blockforia) {
      return 'pp neue montreal' as string;
    }
    return '' as string;
  }

  static brandTransparentColor(transparency = 1): string {
    if (!isBrandDefined()) {
      throwBrandError();
    }
    if (process.env.BRAND === Brand.Frontnode) {
      return `rgba(223, 224, 255, ${transparency})`;
    }
    if (process.env.BRAND === Brand.Omnidrive) {
      return 'rgba(255, 153, 0, 0.15)';
    }
    if (process.env.BRAND === Brand.Blockforia) {
      return `rgba(234, 226, 244, ${transparency})`;
    }
    return `rgba(68, 190, 204, ${transparency})`;
  }
}

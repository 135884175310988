import { Module, VuexAction, VuexModule, VuexMutation } from 'nuxt-property-decorator';
import { $axios, getConfig } from '~/utils/Api';
import { DownloadHelper } from '~/utils/DownloadHelper';
import { OrderHelper } from '~/utils/OrderHelper';
import { TransferBitcoinData } from '~/models/TransferBitcoinData';
import { VbtcTransaction } from '~/models/VbtcTransaction';
import { Transaction } from '~/models/Transaction';
import { VbtcRecommendedFeesResponse } from '~/models/VbtcRecommendedFeesResponse';
import { VbtcRecommendedFees } from '~/models/VbtcRecommendedFees';
import { EnvHelper } from '~/utils/EnvHelper';
import { SellOrderPayload } from '~/models/SellOrderPayload';
import { Currency } from '~/models/Currency';
import { TransactionsStatementRequest } from '~/models/TransactionsStatementRequest';
import { SavedCards, SavedPayouts } from '~/models/SavedPaymentsMethods';
import { GooglePayToken } from '~/models/GooglePay';
import { preferredUserSettings } from '~/models/preferredUserSettings';

const VBTC_API = process.env.VBTC_API;

export interface CreateVirtualOrderData {
  fiat_amount: string;
  currency: string;
  payment_gateway?: boolean;
}

const castToFees = (response: VbtcRecommendedFeesResponse): VbtcRecommendedFees => ({
  fast: response.fast_fee,
  average: response.average_fee,
  slow: response.slow_fee,
});

export type SellPrice = {
  [key in Currency]: {
    fiat: string;
    price: string;
    sell: string;
    buy: string;
  };
};

interface VirtualOrderModuleState {
  transactions: Array<Transaction>;
  activeOrder: VbtcTransaction | null;
  ordersLoading: boolean;
  eventPipe: any;
  orderInProgress: VbtcTransaction | null;
  walletAmount: number | string;
  recommendedFees: Partial<VbtcRecommendedFees>;
  fiat_amount: number;
  fee_amount: number;
  fee_perc: number;
  cardFee: number;
  limitMaxEuro: number;
  limitMaxNok: number;
  limitMinEuro: number;
  limitMinNok: number;
  preferredCurrency: string;
  preferredSettings: preferredUserSettings | null;
  sellPrices: SellPrice;
  cards: Array<SavedCards>;
  cardSaveEnable: boolean;
  payouts: Array<SavedPayouts>;
  ibanSaveEnable: boolean;
}

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class VirtualOrderModule extends VuexModule<VirtualOrderModuleState> implements VirtualOrderModuleState {
  transactions: Array<Transaction> = [];
  activeOrder: VbtcTransaction | null = null;
  ordersLoading = false;
  eventPipe: any;
  orderInProgress: VbtcTransaction | null = null;
  walletAmount: number = 0;
  walletAmountFiat: number = 0;
  btcTransferLimit: number = 0;
  transferPercentTenth: number = 0;
  transferPercentQuarter: number = 0;
  transferPercentHalf: number = 0;
  btcSellLimit: number = 0;
  walletPercentageGains: number = 0;
  btcPrice: number = 0;
  recommendedFees = {};
  cardFee = 0;
  fiat_amount = 0;
  fee_amount = 0;
  fee_perc = 0;
  limitMaxEuro = 0;
  limitMaxNok = 0;
  limitMaxDkk = 0;
  limitMaxSek = 0;
  limitMaxHuf = 0;
  limitMaxPln = 0;
  limitMaxBrl = 0;
  limitMinEuro = 0;
  limitMinNok = 0;
  limitMinDkk = 0;
  limitMinSek = 0;
  limitMinHuf = 0;
  limitMinPln = 0;
  limitMinBrl = 0;
  preferredCurrency: string = '';
  preferredSettings: preferredUserSettings | null = null;
  sellOrder = {
    btc: 0,
    fiat: '',
  };

  sellPrices = {} as SellPrice;

  cards: Array<SavedCards> = [];
  cardSaveEnable = false;
  payouts: Array<SavedPayouts> = [];
  ibanSaveEnable = false;

  get processingOrder(): VbtcTransaction | null {
    return this.orderInProgress;
  }

  get getWalletAmount(): number {
    return this.walletAmount;
  }

  get getWalletAmountFiat(): number {
    return this.walletAmountFiat;
  }

  get getWalletPercentageGains(): number {
    return this.walletPercentageGains;
  }

  get getBtcPrice(): number {
    return this.btcPrice;
  }

  get getBtcTransferLimit() {
    return this.btcTransferLimit;
  }

  get getTransferPercentTenth() {
    return this.transferPercentTenth;
  }

  get getTransferPercentQuarter() {
    return this.transferPercentQuarter;
  }

  get getTransferPercentHalf() {
    return this.transferPercentHalf;
  }

  get getBtcSellLimit() {
    return this.btcSellLimit;
  }

  get getSellOrder(): object {
    return this.sellOrder;
  }

  get getSellPrices(): object {
    return this.sellPrices;
  }

  get getRecommendedFees(): VbtcRecommendedFees | {} {
    return this.recommendedFees;
  }

  get getPreferredCurrency(): string {
    return this.preferredCurrency;
  }

  get getPreferredUserSettings(): preferredUserSettings | null {
    return this.preferredSettings;
  }

  @VuexMutation
  setInProgressOrder(order?: VbtcTransaction) {
    this.orderInProgress = order || null;
  }

  @VuexMutation
  removeInProgressOrder() {
    this.orderInProgress = null;
  }

  @VuexMutation
  setTransactions(transactions: Array<Transaction>) {
    this.transactions = transactions;
  }

  @VuexMutation
  setActiveOrder(order: VbtcTransaction) {
    this.activeOrder = order;
  }

  @VuexMutation
  removeActiveOrder() {
    this.activeOrder = null;
  }

  @VuexMutation
  startLoading(flag: boolean) {
    this.ordersLoading = flag;
  }

  @VuexMutation
  finishedLoading(flag: boolean) {
    this.ordersLoading = flag;
  }

  @VuexMutation
  setOrderEventPipe(pipe: any) {
    this.eventPipe = pipe;
  }

  @VuexMutation
  setWalletAmount(amount: number) {
    this.walletAmount = Number.parseFloat(amount.toFixed(8));
  }

  @VuexMutation
  setWalletAmountFiat(amount: number) {
    this.walletAmountFiat = amount;
  }

  @VuexMutation
  setWalletPercentageGains(percentageGains: number) {
    this.walletPercentageGains = percentageGains;
  }

  @VuexMutation
  setBtcPrice(btcPrice: number) {
    this.btcPrice = btcPrice;
  }

  @VuexMutation
  setBtcTransferLimit(transferLimit: number) {
    this.btcTransferLimit = transferLimit;
  }

  @VuexMutation
  setTransferPercentTenth(percentTenth: number) {
    this.transferPercentTenth = percentTenth;
  }

  @VuexMutation
  setTransferPercentQuarter(percentQuarter: number) {
    this.transferPercentQuarter = percentQuarter;
  }

  @VuexMutation
  setTransferPercentHalf(percentHalf: number) {
    this.transferPercentHalf = percentHalf;
  }

  @VuexMutation
  setBtcSellLimit(sellLimit: number) {
    this.btcSellLimit = sellLimit;
  }

  @VuexMutation
  setFiatAmount(amount: number | string) {
    this.fiat_amount = +amount;
  }

  @VuexMutation
  setFeeAmount(amount: number | string) {
    this.fee_amount = +amount;
  }

  @VuexMutation
  setFeePerc(amount: number | string) {
    this.fee_perc = +amount;
  }

  @VuexMutation
  setCardFee(amount: number | string) {
    this.cardFee = +amount / 100;
  }

  @VuexMutation
  setLimitMaxEuro(amount: number | string) {
    this.limitMaxEuro = +amount;
  }

  @VuexMutation
  setLimitMaxNok(amount: number | string) {
    this.limitMaxNok = +amount;
  }

  @VuexMutation
  setLimitMaxDkk(amount: number | string) {
    this.limitMaxDkk = +amount;
  }

  @VuexMutation
  setLimitMaxSek(amount: number | string) {
    this.limitMaxSek = +amount;
  }

  @VuexMutation
  setLimitMaxHuf(amount: number | string) {
    this.limitMaxHuf = +amount;
  }

  @VuexMutation
  setLimitMaxPln(amount: number | string) {
    this.limitMaxPln = +amount;
  }

  @VuexMutation
  setLimitMaxBrl(amount: number | string) {
    this.limitMaxBrl = +amount;
  }

  @VuexMutation
  setLimitMinEuro(amount: number | string) {
    this.limitMinEuro = +amount;
  }

  @VuexMutation
  setLimitMinNok(amount: number | string) {
    this.limitMinNok = +amount;
  }

  @VuexMutation
  setLimitMinDkk(amount: number | string) {
    this.limitMinDkk = +amount;
  }

  @VuexMutation
  setLimitMinSek(amount: number | string) {
    this.limitMinSek = +amount;
  }

  @VuexMutation
  setLimitMinHuf(amount: number | string) {
    this.limitMinHuf = +amount;
  }

  @VuexMutation
  setLimitMinPln(amount: number | string) {
    this.limitMinPln = +amount;
  }

  @VuexMutation
  setLimitMinBrl(amount: number | string) {
    this.limitMinBrl = +amount;
  }

  @VuexMutation
  setPreferredUserSettings(preferredSettings: preferredUserSettings) {
    this.preferredSettings = preferredSettings;
  }

  @VuexMutation
  setPreferredCurrency(preferredCurrency: string) {
    this.preferredCurrency = preferredCurrency;
  }

  @VuexMutation
  setSellOrder(sellOrder: { btc: number; fiat: string }) {
    this.sellOrder = sellOrder;
  }

  @VuexMutation
  setSellPrices(sellPrices: SellPrice) {
    this.sellPrices = sellPrices;
  }

  @VuexMutation
  setCards(data: { cards: Array<SavedCards>; card_save_enable: boolean }) {
    this.cards = data.cards;
    this.cardSaveEnable = data.card_save_enable;
  }

  @VuexMutation
  setPayouts(data: { iban_data: Array<SavedPayouts>; iban_save_enable: boolean }) {
    this.payouts = data.iban_data;
    this.ibanSaveEnable = data.iban_save_enable;
  }

  @VuexMutation
  removeCard(cardId: string) {
    this.cards = this.cards.filter((card) => card.card_id !== cardId);
  }

  @VuexMutation
  removePayout(payoutId: string) {
    this.payouts = this.payouts.filter((iban) => iban.payout_id !== payoutId);
  }

  @VuexAction({ rawError: true })
  async fetchCards() {
    const { data } = await $axios.get(`${VBTC_API}/user/cards`, getConfig(this.context));
    this.context.commit('setCards', data);
  }

  @VuexAction({ rawError: true })
  async fetchPayouts() {
    const { data } = await $axios.get(`${VBTC_API}/payouts`, getConfig(this.context));
    this.context.commit('setPayouts', data);
  }

  @VuexAction({ rawError: true })
  async deletePayout(payoutId: string) {
    await $axios.delete(`${VBTC_API}/payouts/${payoutId}`, getConfig(this.context));
    this.context.commit('removePayout', payoutId);
  }

  @VuexAction({ rawError: true })
  async deleteCard(cardId: string) {
    await $axios.delete(`${VBTC_API}/user/cards/${cardId}`, getConfig(this.context));
    this.context.commit('removeCard', cardId);
  }

  @VuexAction({ rawError: true })
  async fetchTransactionsStatementFile(payload: TransactionsStatementRequest): Promise<string> {
    return (
      await $axios.get(`${VBTC_API}/transactions-statement`, {
        ...getConfig(this.context),
        params: payload,
      })
    ).data;
  }

  @VuexAction({ rawError: true })
  async fetchPreferredUserSettings(): Promise<preferredUserSettings> {
    const { data } = await $axios.get(`${VBTC_API}/user/settings`, getConfig(this.context));
    this.context.commit('setPreferredUserSettings', data);

    return data;
  }

  @VuexAction({ rawError: true })
  async fetchTransactions(
    pagination: { page: number; limit: number } = { page: 1, limit: 50 },
  ): Promise<Array<Transaction>> {
    this.context.commit('startLoading', true);
    const { data }: { data: Array<Transaction> } = await $axios.get(`${VBTC_API}/transactions`, {
      ...getConfig(this.context),
      params: pagination,
    });
    const transactionInProcess = data.find(OrderHelper.isTransactionInProcess);
    if (transactionInProcess) {
      this.context.commit('setActiveOrder', transactionInProcess);
    }
    this.context.commit('setTransactions', data);
    setTimeout(() => {
      this.context.commit('finishedLoading', false);
    }, 500);
    return data;
  }

  @VuexAction({ rawError: true })
  async getTotalAmount(): Promise<number> {
    const { data } = await $axios.get(`${VBTC_API}/v2/amount `, getConfig(this.context));
    this.context.commit('setPreferredCurrency', data?.preferredCurrency);
    this.context.commit('setWalletAmount', +data?.vbtcAmount);
    this.context.commit('setWalletAmountFiat', +data?.walletBalance);
    this.context.commit('setWalletPercentageGains', +data?.gains);
    this.context.commit('setBtcPrice', +data?.btcPrice);
    this.context.commit('setBtcTransferLimit', +data?.btcTransferLimit);
    this.context.commit('setTransferPercentTenth', +data?.transferPercentTenth);
    this.context.commit('setTransferPercentQuarter', +data?.transferPercentQuarter);
    this.context.commit('setTransferPercentHalf', +data?.transferPercentHalf);
    this.context.commit('setBtcSellLimit', +data?.btcSellLimit);
    this.context.commit('setFiatAmount', +data?.fiat_amount);
    this.context.commit('setFeeAmount', +data?.fee_amount);
    this.context.commit('setFeePerc', +data?.fee_perc);
    this.context.commit('setCardFee', +data?.cardFee);
    this.context.commit('setLimitMaxEuro', +data?.limitMaxEuro);
    this.context.commit('setLimitMinEuro', +data?.limitMinEuro);
    this.context.commit('setLimitMaxNok', +data?.limitMaxNok);
    this.context.commit('setLimitMinNok', +data?.limitMinNok);
    this.context.commit('setLimitMaxDkk', +data?.limitMaxDkk);
    this.context.commit('setLimitMinDkk', +data?.limitMinDkk);
    this.context.commit('setLimitMaxSek', +data?.limitMaxSek);
    this.context.commit('setLimitMinSek', +data?.limitMinSek);
    this.context.commit('setLimitMaxHuf', +data?.limitMaxHuf);
    this.context.commit('setLimitMinHuf', +data?.limitMinHuf);
    this.context.commit('setLimitMaxPln', +data?.limitMaxPln);
    this.context.commit('setLimitMinPln', +data?.limitMinPln);
    this.context.commit('setLimitMaxBrl', +data?.limitMaxBrl);
    this.context.commit('setLimitMinBrl', +data?.limitMinBrl);

    return +data?.vbtcAmount;
  }

  @VuexAction({ rawError: true })
  async create(data: CreateVirtualOrderData): Promise<VbtcTransaction> {
    const { data: transaction } = await $axios.post<VbtcTransaction>(
      `${VBTC_API}/create_order`,
      data,
      getConfig(this.context),
    );
    this.context.commit('setTransactions', [transaction, ...this.transactions]);
    this.context.commit('removeInProgressOrder');
    this.context.commit('setInProgressOrder', transaction);
    return transaction;
  }

  @VuexAction({ rawError: true })
  async capture(activeOrder: VbtcTransaction) {
    const requestId = activeOrder.requestID || (activeOrder as any).requestId;
    const url = `${VBTC_API}/capture/${requestId}`;
    if (!EnvHelper.isPaymentFlowFeatureEnabled() || process.env.PAYMENT_SDK_ENVIRONMENT === 'sandbox') {
      this.context.commit('setInProgressOrder', activeOrder);
      try {
        await $axios.post(url, {}, getConfig(this.context));
      } catch (e: any) {
        if (e.message === 'Request failed with status code 304' && process.env.PAYMENT_SDK_ENVIRONMENT === 'sandbox') {
          return;
        }
        throw e;
      }
      return;
    }
    let status = 304;
    while (status === 304) {
      try {
        const response = await $axios.post(url, {}, getConfig(this.context));
        status = response.status;
        await new Promise((resolve) => setTimeout(resolve, 150));
      } catch (e: any) {
        if (e.message !== 'Request failed with status code 304') {
          throw e;
        }
      }
    }
  }

  @VuexAction({ rawError: true })
  async downloadTransactionInvoice(requestId: string) {
    const { data } = await $axios.get(`${process.env.VBTC_API}/pdfinvoice/${requestId}`, {
      ...getConfig(this.context),
      responseType: 'arraybuffer',
    });
    const blob = new Blob([data], { type: 'application/pdf' });
    DownloadHelper.saveFile(blob, `${requestId}.pdf`);
  }

  @VuexAction({ rawError: true })
  async transferBitcoin(body: TransferBitcoinData): Promise<VbtcTransaction> {
    const { data } = await $axios.post<VbtcTransaction>(`${VBTC_API}/transfer`, body, getConfig(this.context));
    return data;
  }

  @VuexAction({ rawError: true })
  async fetchRecommendedFees() {
    const url = `${VBTC_API}/recommended-fees`;
    const { data: recommendedFeesResponse } = await $axios.get<VbtcRecommendedFeesResponse>(
      url,
      getConfig(this.context),
    );
    this.context.commit('setRecommendedFees', castToFees(recommendedFeesResponse));
  }

  @VuexAction({ rawError: true })
  async sellBtcOrder(payload: SellOrderPayload) {
    try {
      return (
        await $axios.post<{ statusCode: number; message: string }>(
          `${VBTC_API}/sell-crypto`,
          payload,
          getConfig(this.context),
        )
      ).data;
    } catch (er: any) {
      return er?.response?.data;
    }
  }

  @VuexAction({ rawError: true })
  async updateSellPrices() {
    try {
      const { data } = await $axios.get(`${VBTC_API}/sell_price`);
      this.context.commit('setSellPrices', data);
    } catch {}
  }

  @VuexAction
  async updatePreferredUserSettings(data: { currency: string; payment: string }) {
    await $axios.put(
      `${VBTC_API}/user/settings`,
      { preferred_currency: data.currency, payment_method: data.payment },
      getConfig(this.context),
    );
  }

  @VuexAction({ rawError: true })
  async googlePayPayment(data: { invoiceId: string; amount: number; currency: string; token: GooglePayToken }) {
    await $axios.post(
      `${process.env.VBTC_API}/gpay/payment`,
      {
        orderId: data.invoiceId,
        amount: data.amount,
        currency: data.currency,
        screenHeight: String(window.innerHeight),
        screenWidth: String(window.innerWidth),
        protocolVersion: data.token.protocolVersion,
        signature: data.token.signature,
        signedMessage: data.token.signedMessage,
      },
      getConfig(this.context),
    );
  }

  @VuexAction({ rawError: true })
  async createPixPaymentUrl(data: {
    order_id: string;
    amount: number;
    currency: string;
    url_success: string;
    url_fail: string;
  }) {
    try {
      const response = await $axios.post(
        `${process.env.VBTC_API}/payment_url`,
        {
          order_id: data.order_id,
          amount: data.amount,
          currency: data.currency,
          url_success: data.url_success,
          url_failure: data.url_fail,
        },
        getConfig(this.context),
      );

      return response.data;
    } catch (error) {
      console.error('Error in createPixPaymentUrl:', error);
      throw error;
    }
  }

  @VuexAction({ rawError: true })
  async pixPaymentData(order_id: string | undefined) {
    const { data } = await $axios.get(`${VBTC_API}/transactions/${order_id}?redirect=success`, getConfig(this.context));

    return data;
  }

  @VuexMutation
  setRecommendedFees(fees: VbtcRecommendedFees) {
    this.recommendedFees = fees;
  }
}
